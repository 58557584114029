@font-face {
    font-family: Transit;
    src: local('Transit'),
        url('../assets/fonts/BVG_Transit_Web/Regular/TransitWeb.eot');
}

/* for WOFF-capable browsers */
@font-face {
    font-family: Transit;
    src: local('Transit'),
        url('../assets/fonts/BVG_Transit_Web/Regular/TransitWeb.woff') format('woff');
}

@font-face {
    font-family: TransitPict;
    src: local('TransitPict'),
        url('../assets/fonts/BVG_Transit_Web/Pict/Transit-Pict.eot');
}

/* for WOFF-capable browsers */
@font-face {
    font-family: TransitPict;
    src: local('TransitPict'),
        url('../assets/fonts/BVG_Transit_Web/Pict/Transit-Pict.woff') format('woff');
}

body {
    margin: 0;
    font-family: Transit, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
    width: 100%;
    height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* leaflet */

.leaflet-popup .leaflet-popup-content-wrapper {
    box-shadow: 0 4px 4px rgb(0 0 0 / 30%);
    border-radius: 8px;
}

.leaflet-popup-content {
    margin: 0 !important;
    margin-right: 24px !important;
}

.leaflet-container a.leaflet-popup-close-button {
    font: 28px/32px Transit, sans-serif !important;
    font-weight: 400 !important;
    margin-right: 4px;
}
